<template>
  <div>
    <teacher-account-details v-if="isTeacher"></teacher-account-details>
    <student-account-details v-else-if="isStudent"></student-account-details>
  </div>
</template>

<script>
import TeacherAccountDetails from "./teacheraccount/TeacherAccountDetails.vue";
import StudentAccountDetails from "./studentaccount/StudentAccountDetails.vue";

export default {
  name: "AccountDetails",
  components: {
    TeacherAccountDetails,
    StudentAccountDetails
  },
  computed: {
    userFromStore() {
      return this.$store.getters[this.$store.$types.GET_USER_DETAILS];
    },
    isTeacher() {
      if (this.userFromStore.authorized_views.includes("TEACHER_VIEW")) {
        return true;
      } else {
        return false;
      }
    },
    isStudent() {
      if (this.userFromStore.authorized_views.includes("STUDENT_VIEW")) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
