var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { class: _vm.classes.container }, [
    _c("div", { class: _vm.classes.card }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.phase == 0,
              expression: "phase == 0",
            },
          ],
        },
        [
          _c(
            "h3",
            {
              class: [
                _vm.classes.buttonText,
                "xl:text-xl lg:text-xl md:text-base sm:text-sm",
              ],
              staticStyle: { color: "#4c4b4b" },
            },
            [_vm._v(" Account Control ")]
          ),
          _c(
            "v-btn",
            {
              staticStyle: { color: "#676767", "text-transform": "none" },
              attrs: { small: _vm.isSmall, outlined: "" },
              on: {
                click: function ($event) {
                  _vm.phase = 1
                },
              },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "xl:text-base xl:text-base xl:text-base xl:text-base",
                },
                [_vm._v("Delete Account")]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.phase == 1,
              expression: "phase == 1",
            },
          ],
        },
        [
          _c(
            "h3",
            {
              class: [
                _vm.classes.buttonText,
                "decline--text xl:text-xl lg:text-xl md:text-base sm:text-sm",
              ],
            },
            [_vm._v(" Do You Want To Delete Your Account? ")]
          ),
          _c(
            "p",
            { staticClass: "xl:text-base lg:text-sm md:text-sm sm:text-xxs" },
            [
              _vm._v(
                " Please enter your password to confirm the deactivation of your account "
              ),
            ]
          ),
          _c(
            "v-card",
            {
              staticClass: "mt-5",
              attrs: {
                flat: "",
                color: "transparent",
                width: _vm.sizes.card.width,
              },
            },
            [
              _vm.userAuthProvider.toLowerCase() === "password"
                ? _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "ml-0 mt-0",
                          staticStyle: { "font-size": "12px" },
                          attrs: { "no-gutters": "" },
                        },
                        [_vm._v(" Current Password ")]
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "form",
                                  model: {
                                    value: _vm.valid,
                                    callback: function ($$v) {
                                      _vm.valid = $$v
                                    },
                                    expression: "valid",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: _vm.isSmall,
                                      "single-line": "",
                                      solo: "",
                                      outlined: "",
                                      flat: "",
                                      placeholder:
                                        "Enter your current password....",
                                      type: "password",
                                      autocomplete: "new-password",
                                      rules: [
                                        _vm.required,
                                        _vm.passwordValidation,
                                      ],
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.confirmDeleteAccount.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.password,
                                      callback: function ($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "pt-1", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-2", attrs: { cols: "7" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: {
                            small: _vm.isSmall,
                            loading: _vm.deactivating,
                            depressed: "",
                            block: "",
                            color: "#ff4848",
                          },
                          on: { click: _vm.confirmDeleteAccount },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "xl:text-base xl:text-base xl:text-base xl:text-base",
                            },
                            [_vm._v("Delete")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pl-2", attrs: { cols: "5" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: {
                            color: "#676767",
                            "text-transform": "none",
                          },
                          attrs: { small: _vm.isSmall, outlined: "" },
                          on: {
                            click: function ($event) {
                              _vm.phase = 0
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "xl:text-base xl:text-base xl:text-base xl:text-base",
                            },
                            [_vm._v("Cancel")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }