<template>
  <v-container :class="classes.container">
    <div :class="classes.card">
      <div v-show="phase == 0">
        <h3
          style="color: #4c4b4b"
          :class="[
            classes.buttonText,
            'xl:text-xl lg:text-xl md:text-base sm:text-sm',
          ]"
        >
          Account Control
        </h3>
        <v-btn
          :small="isSmall"
          outlined
          style="color: #676767; text-transform: none"
          @click="phase = 1"
        >
          <span class="xl:text-base xl:text-base xl:text-base xl:text-base"
            >Delete Account</span
          >
        </v-btn>
      </div>
      <div v-show="phase == 1">
        <h3
          :class="[
            classes.buttonText,
            'decline--text xl:text-xl lg:text-xl md:text-base sm:text-sm',
          ]"
        >
          Do You Want To Delete Your Account?
        </h3>
        <p class="xl:text-base lg:text-sm md:text-sm sm:text-xxs">
          Please enter your password to confirm the deactivation of your account
        </p>
        <v-card flat class="mt-5" color="transparent" :width="sizes.card.width">
          <div v-if="userAuthProvider.toLowerCase() === 'password'">
            <v-row style="font-size: 12px" no-gutters class="ml-0 mt-0">
              Current Password
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-form ref="form" v-model="valid">
                  <v-text-field
                    :dense="isSmall"
                    single-line
                    solo
                    outlined
                    flat
                    placeholder="Enter your current password...."
                    v-model="password"
                    type="password"
                    autocomplete="new-password"
                    :rules="[required, passwordValidation]"
                    @keydown.enter.prevent="confirmDeleteAccount"
                  ></v-text-field>
                </v-form>
              </v-col>
            </v-row>
          </div>
          <v-row no-gutters class="pt-1">
            <v-col cols="7" class="pr-2">
              <v-btn
                :small="isSmall"
                :loading="deactivating"
                depressed
                block
                class="white--text"
                color="#ff4848"
                @click="confirmDeleteAccount"
                ><span
                  class="xl:text-base xl:text-base xl:text-base xl:text-base"
                  >Delete</span
                ></v-btn
              >
            </v-col>
            <v-col cols="5" class="pl-2">
              <v-btn
                :small="isSmall"
                outlined
                style="color: #676767; text-transform: none"
                @click="phase = 0"
              >
                <span
                  class="xl:text-base xl:text-base xl:text-base xl:text-base"
                  >Cancel</span
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import { FieldValidations } from "/global/utils/validations.js";
import signin from "@af/modules/auth/mixins/signin";

export default {
  name: "DeleteAccountComponent",
  mixins: [FieldValidations, signin],
  data() {
    return {
      phase: 0,
      valid: undefined,
      password: undefined,
      deactivating: false,
      success: false
    };
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    isSmall() {
      return this.screenWidth < 600;
    },
    classes() {
      return {
        container: this.breakPointValues("pa-0", "pa-0", "pt-12", "pt-15"),
        card: this.breakPointValues(
          "bg-white py-3 px-3",
          "bg-white py-6 px-5",
          "",
          ""
        ),
        buttonText: this.breakPointValues("pb-2", "pb-2", "pb-5", "pb-5"),
      };
    },
    sizes() {
      return {
        card: {
          width: this.breakPointValues("100%", "80%", "80%", "48%"),
        },
      };
    },
    userEmail() {
      return this.$store.state.user.user.email;
    },
    userAuthProvider() {
      return this.$store.state.user.user.provider;
    },
  },
  methods: {
    breakPointValues(smValue, mdValue, lgValue, xlValue) {
      let s = this.screenWidth;
      return s < 600
        ? smValue
        : s < 961
        ? mdValue
        : s < 1903
        ? lgValue
        : xlValue;
    },
    async confirmDeleteAccount() {
      if(!this.valid) return;
      if (this.deactivating) return;
      try {
        this.deactivating = true;
        if (this.userAuthProvider.toLowerCase() === "password")
          await this.deleteViaPassword();
        else await this.deleteViaGoogle();
        this.success = true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.deactivating = false;
        if(this.success) this.$store.dispatch("user/logout");
      }
    },
    async deleteViaPassword() {
      if (!this.$refs.form.validate()) {
        return;
      }
      await this.$store.dispatch("userAF/deleteAccount", {
        provider: this.userAuthProvider,
        email: this.userEmail,
        password: this.password,
      });
    },
    // This function requires registration of this url into firebase to work so currently this does not work
    async deleteViaGoogle() {
      const data = await this.validate_google_data();
      await this.$store.dispatch("userAF/deleteAccount", {
        provider: this.userAuthProvider,
        ...data,
      });
    },
  },
};
</script>