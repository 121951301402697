var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { class: _vm.classes.container }, [
    _c(
      "div",
      { class: ["rounded", _vm.classes.divCard] },
      [
        _c(
          "h2",
          {
            staticClass: "xl:text-2xl lg:text-2xl md:text-xl sm:text-base",
            staticStyle: { color: "#4c4b4b" },
          },
          [_vm._v(" Security Details ")]
        ),
        !_vm.isSmall ? _c("br") : _vm._e(),
        _c("br"),
        _c(
          "H3",
          {
            class: [
              _vm.classes.buttonText,
              "xl:text-xl lg:text-xl md:text-base sm:text-sm",
            ],
            staticStyle: { color: "#4c4b4b" },
          },
          [_vm._v(" Password ")]
        ),
        !_vm.editPassword
          ? _c(
              "v-btn",
              {
                staticStyle: { color: "#676767", "text-transform": "none" },
                attrs: { outlined: "", small: _vm.isSmall },
                on: { click: _vm.showEditPassword },
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "xl:text-base xl:text-base xl:text-base xl:text-base",
                  },
                  [_vm._v("Change password")]
                ),
              ]
            )
          : _vm._e(),
        !_vm.editPassword && _vm.updatedPasswordDuration > 0
          ? _c(
              "p",
              {
                class: [
                  "xl:text-base lg:text-sm md:text-sm sm:text-xxs",
                  _vm.classes.changeText,
                ],
              },
              [
                _vm._v(
                  " password changed " +
                    _vm._s(_vm.updatedPasswordDuration) +
                    " days ago "
                ),
              ]
            )
          : _vm._e(),
        _vm.updatingPasswordSuccessful
          ? _c(
              "v-row",
              {
                staticClass: "mr-12 pr-12 mt-6",
                staticStyle: { "justify-content": "center", color: "green" },
              },
              [_vm._v(_vm._s(_vm.returnMessage["data"]))]
            )
          : _vm._e(),
        _vm.editPassword
          ? _c(
              "v-row",
              { staticClass: "mt-3", attrs: { "no-gutters": !_vm.isSmall } },
              [
                _c(
                  "v-col",
                  [
                    _c(
                      "v-card",
                      {
                        staticStyle: { "box-shadow": "none" },
                        attrs: {
                          width: _vm.sizes.securityCard.width,
                          color: "#00000000",
                        },
                      },
                      [
                        _vm.returnMessage !== undefined
                          ? _c("submission-alert-message", {
                              ref: "alertMessage",
                              attrs: { message: _vm.returnMessage["data"] },
                              model: {
                                value: _vm.showGeneralMessage,
                                callback: function ($$v) {
                                  _vm.showGeneralMessage = $$v
                                },
                                expression: "showGeneralMessage",
                              },
                            })
                          : _c("submission-alert-message", {
                              ref: "alertMessage",
                              attrs: {
                                message:
                                  _vm.badSubmissionMessage[_vm.$language],
                              },
                              model: {
                                value: _vm.showGeneralMessage,
                                callback: function ($$v) {
                                  _vm.showGeneralMessage = $$v
                                },
                                expression: "showGeneralMessage",
                              },
                            }),
                        _c(
                          "v-row",
                          {
                            staticClass: "ml-0 mt-0",
                            staticStyle: { "font-size": "12px" },
                            attrs: { "no-gutters": "" },
                          },
                          [_vm._v(" Old Password ")]
                        ),
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              [
                                _c("v-text-field", {
                                  ref: "oldPasswordField",
                                  attrs: {
                                    dense: _vm.isSmall,
                                    "single-line": "",
                                    solo: "",
                                    outlined: "",
                                    flat: "",
                                    label: "Old Password",
                                    placeholder: "Enter your old password....",
                                    rules: [
                                      (v) => _vm.required(v, "old Password"),
                                    ],
                                    type: "password",
                                  },
                                  model: {
                                    value: _vm.oldPassword,
                                    callback: function ($$v) {
                                      _vm.oldPassword = $$v
                                    },
                                    expression: "oldPassword",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          {
                            staticStyle: { "font-size": "12px" },
                            attrs: { "no-gutters": "" },
                          },
                          [
                            _vm._v(
                              "Your new password must have at least 8 characters (mix of lower/uppercase) and include a number and a symbol."
                            ),
                          ]
                        ),
                        _c(
                          "v-row",
                          {
                            staticClass: "mt-5",
                            staticStyle: { "font-size": "12px" },
                            attrs: { "no-gutters": "" },
                          },
                          [_vm._v("New Password")]
                        ),
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              [
                                _c("v-text-field", {
                                  ref: "newpasswordField",
                                  attrs: {
                                    dense: _vm.isSmall,
                                    "single-line": "",
                                    solo: "",
                                    flat: "",
                                    outlined: "",
                                    label: "New Password",
                                    placeholder: "Enter your new password....",
                                    rules: [
                                      (v) => _vm.required(v, "new password"),
                                      _vm.passwordValidation,
                                      (v) =>
                                        v !== this.oldPassword ||
                                        "Old password and new password cannot be the same",
                                    ],
                                    type: "password",
                                  },
                                  model: {
                                    value: _vm.newPassword,
                                    callback: function ($$v) {
                                      _vm.newPassword = $$v
                                    },
                                    expression: "newPassword",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          {
                            staticClass: "mt-5",
                            staticStyle: { "font-size": "12px" },
                            attrs: { "no-gutters": "" },
                          },
                          [_vm._v("Re-enter New Password")]
                        ),
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              [
                                _c("v-text-field", {
                                  ref: "confNewPasswordField",
                                  attrs: {
                                    dense: _vm.isSmall,
                                    "single-line": "",
                                    solo: "",
                                    flat: "",
                                    outlined: "",
                                    label: "Confirm Password",
                                    placeholder:
                                      "Re-Enter your new password....",
                                    rules: _vm.confNewPasswordRules,
                                    type: "password",
                                  },
                                  model: {
                                    value: _vm.confNewPassword,
                                    callback: function ($$v) {
                                      _vm.confNewPassword = $$v
                                    },
                                    expression: "confNewPassword",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { attrs: { justify: "start", "no-gutters": "" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "white--text",
                                staticStyle: { "text-transform": "none" },
                                attrs: {
                                  color: "#0099DC",
                                  loading: _vm.updatingPassword,
                                },
                                on: { click: _vm.updatePassword },
                              },
                              [_vm._v("Save")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-3 black--text",
                                staticStyle: {
                                  "text-transform": "none",
                                  "border-color": "#d6d6d6",
                                },
                                attrs: { outlined: "" },
                                on: { click: _vm.cancelEditPassword },
                              },
                              [_vm._v("Cancel")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        !_vm.isSmall ? _c("br") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }