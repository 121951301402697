var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        {
          staticStyle: { "box-shadow": "none", padding: "27px 32px 35px 32px" },
          attrs: { outlined: !_vm.readOnly ? true : false },
        },
        [
          _c(
            "h2",
            { staticClass: "mb-10", staticStyle: { color: "#4c4b4b" } },
            [_vm._v("Account Details")]
          ),
          _c(
            "v-card",
            {
              class: _vm.classes.profileBox,
              staticStyle: { "box-shadow": "none", padding: "20px" },
              attrs: { color: "#E5F4FB" },
            },
            [
              _c(
                "v-row",
                { attrs: { justify: "start" } },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "row",
                        "justify-content": "center",
                        "margin-top": "20px",
                      },
                      attrs: { cols: _vm.cols.profile.left },
                    },
                    [
                      _c(
                        "v-avatar",
                        {
                          class:
                            _vm.classes.avatar[
                              _vm.isExist(_vm.updatedProfileData, "avatar_link")
                            ],
                          attrs: {
                            color: "primary",
                            height: _vm.sizes.avatar.height,
                            width: _vm.sizes.avatar.width,
                          },
                        },
                        [
                          _vm.avatarDisplayCondition
                            ? _c("img", {
                                attrs: {
                                  src: _vm.getImageURL(),
                                  alt: "",
                                  width: "148",
                                  height: "148",
                                },
                              })
                            : _c("span", [_vm._v(_vm._s(_vm.initials))]),
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "button",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.readOnly,
                                                  expression: "!readOnly",
                                                },
                                              ],
                                              staticClass:
                                                "avatar-edit-button pa-1 xl:text-base lg:text-sm md:text-sm sm:text-xs",
                                            },
                                            "button",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" Change ")]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.showAvatarChageMenu,
                                callback: function ($$v) {
                                  _vm.showAvatarChageMenu = $$v
                                },
                                expression: "showAvatarChageMenu",
                              },
                            },
                            [
                              _c(
                                "v-list",
                                { staticStyle: { padding: "0px 0px" } },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass:
                                        "px-3 py-0 d-flex justify-center",
                                      on: { click: _vm.changeAvatar },
                                    },
                                    [_vm._v("Upload Photo ")]
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass:
                                        "px-3 py-0 d-flex justify-center",
                                      on: { click: _vm.removeAvatar },
                                    },
                                    [_vm._v("Remove ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("input", {
                            ref: "file",
                            staticStyle: { display: "none" },
                            attrs: { type: "file", accept: "image/*" },
                            on: {
                              change: function ($event) {
                                return _vm.loadImageFile($event.target.files)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex flex-column justify-space-between",
                      attrs: { cols: _vm.cols.profile.right },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "xl:text-3xl lg:text-2xl md:text-2xl sm:text-xl",
                          staticStyle: { color: "#29637d" },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.profile.name,
                                expression: "profile.name",
                              },
                            ],
                            class: [{ inert: _vm.readOnly }, "name-input"],
                            attrs: { type: "text" },
                            domProps: { value: _vm.profile.name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.profile,
                                  "name",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ft-medium xl:text-base lg:text-base md:text-base sm:text-sm",
                        },
                        [_vm._v(" Student ")]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            {
                              class: _vm.classes.editButton,
                              staticStyle: {
                                "text-transform": "none",
                                color: "#0099dc",
                                padding: "none",
                                "border-color": "#0099dc",
                              },
                              attrs: { outlined: "", small: "" },
                              on: { click: _vm.makeFormEditable },
                            },
                            [_vm._v(" Edit ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: _vm.screenWidth < 600 ? "12" : "6" } },
                [
                  _c(
                    "p",
                    {
                      staticClass: "acc-font",
                      staticStyle: { "margin-bottom": "none !important" },
                    },
                    [_vm._v(" Email Address ")]
                  ),
                  _c("v-text-field", {
                    staticClass: "acc-font",
                    attrs: {
                      readonly: "",
                      outlined: "",
                      height: "30px",
                      value: _vm.profile.email,
                      "hide-details": _vm.isSmall,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("p", { staticClass: "acc-font" }, [
                    _vm._v("Phone Number"),
                  ]),
                  _c("v-text-field", {
                    ref: "phoneNumberField",
                    staticClass: "acc-font",
                    attrs: {
                      readonly: _vm.readOnly,
                      "hide-details": _vm.isSmall,
                      outlined: "",
                      height: "46px",
                      placeholder: _vm.profile.phone,
                      rules: [_vm.phoneValidation],
                    },
                    model: {
                      value: _vm.updatedProfileData.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.updatedProfileData, "phone", $$v)
                      },
                      expression: "updatedProfileData.phone",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("p", { staticClass: "acc-font" }, [
                    _vm._v("Present Address"),
                  ]),
                  _c("v-textarea", {
                    staticClass: "acc-font",
                    attrs: {
                      readonly: _vm.readOnly,
                      outlined: "",
                      placeholder: _vm.readOnly
                        ? "N/A"
                        : _vm.isExist(_vm.updatedProfileData, "address")
                        ? _vm.updatedProfileData.address
                        : "Type your present address here...",
                      "hide-details": _vm.isSmall,
                      "on-resize": "",
                      rows: "3",
                      counter: _vm.aboutTextMaxString,
                    },
                    model: {
                      value: _vm.updatedProfileData.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.updatedProfileData, "address", $$v)
                      },
                      expression: "updatedProfileData.address",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("p", { staticClass: "acc-font" }, [_vm._v("Institution")]),
                  _c("v-text-field", {
                    staticClass: "acc-font",
                    attrs: {
                      readonly: _vm.readOnly,
                      outlined: "",
                      height: "46px",
                      counter: _vm.institutionMaxString,
                      "hide-details": _vm.isSmall,
                      placeholder: _vm.readOnly
                        ? "N/A"
                        : _vm.isExist(_vm.updatedProfileData, "institution")
                        ? _vm.updatedProfileData.institution
                        : "Write you institution here...",
                    },
                    model: {
                      value: _vm.updatedProfileData.institution,
                      callback: function ($$v) {
                        _vm.$set(_vm.updatedProfileData, "institution", $$v)
                      },
                      expression: "updatedProfileData.institution",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c("submission-alert-message", {
        ref: "banner",
        attrs: { message: _vm.alertMessage },
        model: {
          value: _vm.showFormAlertMessage,
          callback: function ($$v) {
            _vm.showFormAlertMessage = $$v
          },
          expression: "showFormAlertMessage",
        },
      }),
      _c("br"),
      _c(
        "v-card",
        {
          staticStyle: {
            "box-shadow": "none",
            padding: "36px 32px",
            "margin-bottom": "20px",
          },
        },
        [
          _c("v-row", { attrs: { justify: "space-between" } }, [
            _c("h3", { staticClass: "sm:text-sm d-flex align-center" }, [
              _vm._v("Guardian"),
            ]),
          ]),
          _c(
            "v-form",
            {
              model: {
                value: _vm.guardianForm,
                callback: function ($$v) {
                  _vm.guardianForm = $$v
                },
                expression: "guardianForm",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.screenWidth < 600 ? "12" : "6" } },
                    [
                      _c("p", { staticClass: "acc-font" }, [_vm._v("Name")]),
                      _c("v-text-field", {
                        staticClass: "acc-font",
                        attrs: {
                          "hide-details": _vm.isSmall,
                          readonly: _vm.readOnly,
                          outlined: "",
                          height: "46px",
                        },
                        model: {
                          value: _vm.guardian.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.guardian, "name", $$v)
                          },
                          expression: "guardian.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.screenWidth < 600 ? "12" : "6" } },
                    [
                      _c("p", { staticClass: "acc-font" }, [
                        _vm._v("Relationship"),
                      ]),
                      _c("v-text-field", {
                        staticClass: "acc-font",
                        attrs: {
                          "hide-details": _vm.isSmall,
                          readonly: _vm.readOnly,
                          outlined: "",
                          height: "46px",
                        },
                        model: {
                          value: _vm.guardian.relation,
                          callback: function ($$v) {
                            _vm.$set(_vm.guardian, "relation", $$v)
                          },
                          expression: "guardian.relation",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.screenWidth < 600 ? "12" : "6" } },
                    [
                      _c("p", { staticClass: "acc-font" }, [
                        _vm._v("Phone Number"),
                      ]),
                      _c("v-text-field", {
                        ref: "gphonfield",
                        staticClass: "acc-font",
                        attrs: {
                          "hide-details": _vm.isSmall,
                          readonly: _vm.readOnly,
                          outlined: "",
                          height: "46px",
                          disabled: _vm.readOnly ? true : false,
                          rules: _vm.readOnly
                            ? [() => true]
                            : [_vm.phoneValidation],
                        },
                        model: {
                          value: _vm.guardian.number,
                          callback: function ($$v) {
                            _vm.$set(_vm.guardian, "number", $$v)
                          },
                          expression: "guardian.number",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.screenWidth < 600 ? "12" : "6" } },
                    [
                      _c("p", { staticClass: "acc-font" }, [_vm._v("Email")]),
                      _c("v-text-field", {
                        ref: "gemailfield",
                        staticClass: "acc-font",
                        attrs: {
                          "hide-details": _vm.isSmall,
                          readonly: _vm.readOnly,
                          outlined: "",
                          disabled: _vm.readOnly ? true : false,
                          height: "46px",
                          rules: _vm.readOnly
                            ? [
                                () => {
                                  true
                                },
                              ]
                            : [_vm.emailValidation],
                        },
                        model: {
                          value: _vm.guardian.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.guardian, "email", $$v)
                          },
                          expression: "guardian.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.readOnly
        ? _c(
            "v-row",
            {
              staticStyle: { "margin-right": "25px", padding: "10px" },
              attrs: { justify: "end" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  staticStyle: {
                    "text-transform": "none",
                    "box-shadow": "none",
                  },
                  attrs: { color: "#0099DC", loading: _vm.updatingProfile },
                  on: {
                    click: function ($event) {
                      return _vm.updateProfileInfo()
                    },
                  },
                },
                [_vm._v("Save ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "red--text",
                  staticStyle: {
                    "text-transform": "none",
                    "border-color": "#d6d6d6",
                    "background-color": "#fff",
                    "margin-left": "10px",
                    "box-shadow": "none",
                  },
                  attrs: { outlined: "" },
                  on: { click: _vm.cancelSave },
                },
                [_vm._v(" Cancel ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }